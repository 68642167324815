<template>
    <div>
        <h4 class="mb-1">Новый заказ</h4>
        <v-select
            ref="ContractorSearchElement"
            class="mb-1"
            v-model="contractor"
            :clearable="true"
            label="name"
            placeholder="Выберите клиент"
            :options="contractors"
        />
        <b-form-input
            class="search-form mb-2"
            ref="InputSearchName"
            v-model="search"
            :placeholder="translate.translate('key', 'poiskPoNazvaniyuIliArtikulu')"
            @input="searchDebounce()"
            autocomplete="off"
        />
        <div v-if="loading" class="text-center">
            <b-spinner variant="primary" class="text-center"/>
        </div>
        <div v-else>
            <div class="products">
                <ProductGrid :product="product"
                             class="card" v-for="(product, index) in loadedProducts" :key="index"
                             :order="order"
                             @addProduct="onAddProduct"
                             @clickedProductDetails="clickedProductDetails"
                             @incrementQuantity="onIncrementQuantity"
                             @decrementQuantity="onDecrementQuantity"
                />
            </div>
            <div class="text-center mb-4" v-if="loadedProducts.length > 0 && this.products.length > this.page">
                <b-button variant="primary" @click="loadMore">{{ translate.translate('key', 'pokazatYeshyo') }} 18
                </b-button>
            </div>
            <div class="text-center text-muted">
                <span
                    v-if="!search && loadedProducts.length === 0"
                >{{
                        translate.translate('key', 'nachniteVvoditnazvaniyaTovara')
                    }}</span>
            </div>
            <div class="text-center" v-if="products.length === 0 && !finallySearch && search">
                <h3 class="text-center">☹</h3>
                {{ translate.translate('key', 'povashemuZaprosuNeNayden') }}
            </div>
        </div>
    </div>
</template>

<script>
import translate from '@/translation/translate'
import LoaderComponent from '@/components/LoaderComponent'
import ProductGrid from '@/components/ProductGrid'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { _ } from 'vue-underscore'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
    name: 'NewOrder',
    components: {
        LoaderComponent,
        ProductGrid,
        vSelect
    },
    data() {
        return {
            translate,
            search: '',
            products: [],
            fields: [
                {
                    key: 'name',
                    label: translate.translate('key', 'naimenovaniya')
                },
                {
                    key: 'price',
                    label: translate.translate('key', 'sena')
                },
            ],
            loading: false,
            finallySearch: true,
            contractor: null
        }
    },
    computed: {
        ...mapGetters({
            page: 'getPageAllProducts',
            favoriteProducts: 'favoriteProducts',
            order: 'getOrder',
            items: 'getProducts',
            categoriesTree: 'getCategoriesTree',
            contractors: 'getContractors'
        }),
        loadedProducts() {
            return this.products.slice(0, this.page)
        }
    },
    methods: {
        ...mapMutations(['setPageAllProducts']),
        ...mapActions(['incrementQuantity', 'decrementQuantity', 'addProduct']),
        searchDebounce: _.debounce(function () {
            this.finallySearch = true
            this.products = this.searchStringInArray(this.search)
        }, 1000),
        searchStringInArray(str) {
            str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
            this.loading = true
            setTimeout(() => {
                this.loading = false
                this.finallySearch = false
            }, 1000)

            return this.items.filter(item => {
                let name = item.item.name.toLowerCase()
                let sku = item.item.sku ? item.item.sku.toLowerCase() : ''
                return name.match(str.toLowerCase()
                    .trim()) || sku.match(str.toLowerCase()
                    .trim())
            })

        },
        onIncrementQuantity(product, selectedPackageMeasurement) {
            this.incrementQuantity({
                product,
                selectedPackageMeasurement
            })
        },
        onDecrementQuantity(product, selectedPackageMeasurement) {
            this.decrementQuantity({
                product,
                selectedPackageMeasurement
            })
        },
        onAddProduct(product, selectedPackageMeasurement) {
            if (!this.contractor) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Выберите контрагента',
                        icon: 'AlertTriangleIcon',
                        variant: 'warning',
                    },
                })
                return this.$refs.ContractorSearchElement.$refs.search.focus()
            }
            this.addProduct({
                contractor: this.contractor,
                product,
                quantity: selectedPackageMeasurement.quantity
            })
        },
        loadMore() {
            if (this.page > this.products.length) {
                return
            }
            this.setPageAllProducts(this.page + 15)
        },
        clickedProductDetails() {
            this.$emit('close')
        },
        scrollToProduct(product_id) {
            let el = document.getElementById(product_id)
            this.$scrollTo(el, 500, { offset: -120 })
        },
    },
    mounted() {
        this.products = this.items
        if (this.order && this.order.contractor) {
            this.contractor = this.contractors.find(contractor => contractor.id === this.order.contractor.id)
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'product-details') {
                setTimeout(() => {
                    vm.scrollToProduct(from.params.id)
                }, 1000)
            }
        })
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
